.banner-main{
  display: flex;
  background: #fcd29f;
  align-items: center;
  height: 200px;
  padding: 10px 45px;
  margin: 14px;
}

.source-form-inner{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
}

.available-jobs-form{
  display: flex;
  justify-content: center;
  align-content: center;
}

.schedule-div-outer{
  margin-top: 20px;
  /* display: grid; */
  /* grid-template-columns: 1fr 1fr; */
  /* column-gap: 60px; */
  /* margin-bottom: 20px; */
  /* padding: 10px 45px; */
}

.schedule-div-inner{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
  margin-bottom: 15px;
}

.schedule-div2-inner{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
  margin-bottom: 15px;
}

.sourcedet-div{
  margin-top: 0px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 150px;
  /* height: "115px", */
}

.destinationdet-div-outer{
  margin-top: 30px;
  /* display: grid; */
  /* grid-template-columns: 1fr 1fr; */
  /* column-gap: 60px; */
  margin-bottom: 20px;
  padding: 5px 45px;
}

.destinationdet-div-inner{
  margin-top: 0px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 150px;
}

.columntransf-main{
  max-width: 100%;
  margin-top: 40px;
  /* overflow: scroll; */
  overflow: auto;
  margin-bottom: 20px;
  padding: 12px 35px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: #f5f5f5de;
  border-radius: 5px;
}

.columntransf-header{
  display: grid;
  grid-template-columns: 1.8fr 1.2fr 1.8fr 1.2fr 3fr;
  /* grid-template-columns: 2fr 1.6fr 2fr 1.6fr 3.4fr; */
  column-gap: 25px;

  font-size: 12px;
}

.columntransf-header-args{
  display: grid;
  grid-template-columns: 1.8fr 1.2fr 1.8fr 1.2fr 2.4fr 3fr 0.8fr;
  column-gap: 25px;

  font-size: 12px;
}

.columntransf-taskcol{
  display: grid;
  grid-template-columns: 1.8fr 1.2fr 1.8fr 1.2fr 3fr 0.8fr;
  column-gap: 25px;
  font-size: 12.5px;
}

.small-h3{
  font-size: 20px !important;
}

.columntransf-taskcol-args{
  display: grid;
  grid-template-columns: 1.8fr 1.2fr 1.8fr 1.2fr 2.4fr 3fr 0.8fr;
  column-gap: 25px;
  font-size: 12.5px;
}

.datasource-ip-div-outer{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 100px;
}

.datasource-ip-div-inner{
  border: 1px solid pink;
  border-radius: 6px;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.newsource-ip-div-outer{
  display: flex;
  /* width: 285px; */
  text-align: center;
  /* margin-left: 80px; */
}

.newsource-ip-div-inner{
  border: 1px solid pink;
  border-radius: 6px;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.destconn-form-main{
  display: flex;
  justify-content: center;
  height: 250px;
  margin-top: 25px;
}

.newsource-main{
  border: 1px solid grey;
  margin-top: 110px;
  border-radius: 5px;
}

.reviewdet-main{
  margin-top: 40px;
  /* display: grid; */
  /* grid-template-columns: 1fr 1fr; */
  /* column-gap: 60px; */
  margin-bottom: 20px;
  padding: 5px 45px;
}

.expandable-div-outer{
  margin-top: 15px;
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 150px;
}

.content-inside{
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.content-inside-p{
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.content-inside-p-after{
  padding-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* width: 100%; */
}

.sourcecon-middle-div{
  display: flex;
  justify-content: space-between;
}

.schedule-div2-outer{
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 250px; */
  margin-bottom: 220px;
  margin-top: 25px;
}