.status-modal-container {
  border: 1px solid red;
}

.dialog-content {
  width: 600px;
}

.status-info {
  display: flex;
  align-items: center;
}

.dialog-header {
  display: flex;
  align-items: center;
}

.close-icon {
  cursor: pointer;
}

.check-circle {
  margin-right: 10px;
}

.status-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.overall-box {
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 15px 10px;
  margin: 9px;
  box-shadow: none;
}
