.project-filter {
  padding-top: 20px;
  padding-inline: 4.5%;
}
.project-filter nav {
  display: flex;
  padding: 6px;
  background-color: transparent;

  /* background-color: #fff; */
  /* border: 0.8px solid #247ba0; */
}
.project-filter p {
  margin-right: 10px;
}
.project-filter button {
  background: transparent;
  // font-family: $text__headline__light !important;
  font-size: 20px;
  font-weight: 400;
  border: 0;
  padding-inline: 10px;
  padding-bottom: 8px;
  font-family: inherit;
  /* color: #403f41; */
  color: #666667;
  cursor: pointer;
  /* border-right: 1px solid #e4e4e4; */
}
.project-filter button:last-child {
  border: 0;
}
.project-filter button.active {
  color: #000;
  border-bottom: 1.2px solid #000000;

  /* background: linear-gradient(
    0deg,
    rgba(172, 240, 255, 0.2763699229691877) 0%,
    rgba(74, 148, 113, 0.31278448879551823) 100%
  ); */
  /* padding-block: 2px; */
}
