.form-box{
  max-height: 600px;
  overflow: auto;
}

.d-tree-container {
  list-style: none;
  padding: 0;
  margin: 0;
  // overflow: auto,
  overflow: hidden;
  &:hover{
    overflow: auto;
  }
}


.d-tree-head {
  padding-left: 0rem;
}

.d-tree-node {
  padding: 0.2rem 0rem 0rem 0.2rem;
  padding: 0;
  margin-block: 0.1rem;
  /* padding-left: 1.3rem; */
}

.d-tree-end {
  padding: 0rem 1.5rem 0rem 1.6rem;
}

.d-tree-toggler.active {
  /* transform: rotate(45deg); */
  background-color: #f0f0f0;
  border-radius: 4px;
}

.d-tree-node-toggler.active {
  /* transform: rotate(45deg); */
  /* background-color: #f0f0f0; */
  /* border-radius: 4px; */
  color: #62aed1;
  background-color: #f6f5f5ec;
  font-weight: 600;
  border-radius: 4px;
  /* text-decoration: underline; */
  /* transform: scale(1.035); */
}

.d-tree-toggler:hover {
  background-color: #f0f0f0;
  border-radius: 4px;
}

.foldertree-main{
  /* height: 100%; */
  font-size: 1.1rem;
  position: relative;
  padding: 0;
}

// .foldertree-outer-contanier{
//   height: 80vh;
//   overflow: hidden;
// }

.foldertree-inner-contanier{
  height: 76vh;
  margin-top: 5px;
  // overflow: auto;
  background-color: #fff;
  border-radius: 4px;
  border: 1.2px solid #eaeaea;
}

.foldertree-ip{
  width: 95%;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 12px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
}

.foldertree-search{
  width: 95%;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 12px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
}

.filetree-ip{
  /* height: 100%; */
  font-size: 1.1rem;
  overflow: auto;
  padding: 0;
  padding-left: 2px;
}

.job-btn-plain{
  background-color: $white__color;
  border-color: $border__gray__color;
  font-family: $text__regular;
  color: $font__black__color;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  font-size: 14px;

  &:hover {
    background-color: darken($lightest__gray__color, 8%);
    color: $font__black__color;
  }

  &:active {
    background-color: darken($lightest__gray__color, 8%) !important;
    color: $font__black__color !important;
    outline: none;
  }
}

.maersk-fonts{
  font-family: $text__headline__light !important;
}

.create-job-main{
  font-family: $text__headline__light !important;
}

.width-72p{
  width:72%;
}