li {
  list-style: none;
  margin: 10px;
}

/* .table_wrapper {
  width: "800px";
  border: "1px solid red";
} */

.wrapper {
  display: flex;
  justify-content: space-between;
  padding: 0px 80px;
}

.Product_Table_Wrapper {
  /* border: 1px solid red; */
  padding-left: 50px;
}

/* Set default color for anchor tags */
a {
  color: rgb(79, 78, 78);
  text-decoration: none;
  transition: color 0.3s;
}

a:hover {
  /* brand color */
  color: #62aed1;
  /* text-decoration: underline; */
}

.quick_Link_Wrapper {
  padding: 30px;
  border-left: 1px solid rgb(209, 209, 209);
  margin-top: 80px;
  height: 350px;
}
