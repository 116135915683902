// =======================================
//     List of variables for layout
// =======================================
/*
    description Of variables for build for theme layouts
        1) menu-caption-color
            List of color for sidebar menu caption

        2) brand-background
            List of color for logo background

        3) header-dark-background
            List of color for Dark Header

        4) header-light-background
            List of color for light Header

        5) menu-dark-background
            List of color for Dark sidebar menu

        6) menu-light-background
            List of color for light sidebar menu

        7) menu-active-color
            List of color for Active item highliter

        8) menu-icon-color
            List of color for sidebar menu items icon colors
*/

@font-face {
  font-family: MaerskHeadLine-Regular;
  src: url(../../fonts/MaerskHeadline-Regular.woff);
}
@font-face {
  font-family: MaerskHeadLine-Bold;
  src: url(../../fonts/MaerskHeadline-Bold.woff);
}
@font-face {
  font-family: MaerskHeadline-Light;
  src: url(../../fonts/MaerskHeadline-Light.woff);
}
@font-face {
  font-family: MaerskText-Regular;
  src: url(../../fonts/MaerskText-Regular.woff);
}
@font-face {
  font-family: MaerskText-Light;
  src: url(../../fonts/MaerskText-Light.woff);
}

@font-face {
  font-family: MaerskText-Bold;
  src: url(../../fonts/MaerskText-Bold.woff);
}

$header-height: 4rem;
$Menu-width: 264px;
$Menu-collapsed-width: 80px;

$rg: linear-gradient(
  155deg,
  rgba(145, 216, 242, 1) 0%,
  rgba(181, 224, 245, 1) 100%
);
$resource: linear-gradient(
  155deg,
  rgba(130, 214, 209, 1) 0%,
  rgba(170, 234, 224, 1) 100%
);
$location: linear-gradient(
  155deg,
  rgba(255, 158, 135, 1) 0%,
  rgba(255, 186, 166, 1) 100%
);
$resourcetype: linear-gradient(
  155deg,
  rgba(255, 211, 79, 1) 0%,
  rgba(255, 235, 168, 1) 100%
);

// color list for build layouts
// temp variables
$theme-color: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
$theme-color2: #000000; //linear-gradient(-135deg, #899FD4 0%, #A389D4 100%);
$primary-color: #04a9f5;
$warning-color: #f4c22b;
$danger-color: #f44236;
$success-color: #1de9b6;
$purple-color: #a389d4;
$info-color: #3ebfea;
$secondary-color: #748892;
$default-color: #d6d6d6;
$light-color: #f2f2f2;
$dark-color: #37474f;
$inverse-color: $dark-color;
$theme-border: #eaeaea;

// header
$brand-background: #3f4d67;
$header-dark-background: #3f4d67;
$header-dark-text-color: #fff;
$header-light-background: #fff;
$header-light-text-color: #3f4d67;

// Menu
$menu-caption-color: #14171d;
$menu-dark-background: #293742;
$menu-dark-text-color: #a9b7d0;
$menu-light-background: #fff;
$menu-light-text-color: #3f4d67;
$menu-active-color: #04a9f5;

// dark layout colors
// $dark-layout: #212224; // Old one
$dark-layout: #141414;
$dark-layout-font: #fff;

// light layout colors
$light-layout: #fff;
$light-layout-font: #000;

// Menu icon
$menu-icon-color: $primary-color, #ff5252, #01a9ac, #9575cd, #28ace2,
  $warning-color;
// Header background
$color-header-name: blue, red, purple, lightblue, dark;
$color-header-color: $primary-color, #ff5252, #9575cd, #28ace2,
  lighten($dark-layout, 7%);
// Menu background
$color-menu-name: blue, red, purple, lightblue, dark;
$color-menu-color: $primary-color, #ff5252, #9575cd, #28ace2,
  lighten($dark-layout, 7%);
// Active background color
$color-active-name: blue, red, purple, lightblue, dark;
$color-active-color: $primary-color, #ff5252, #9575cd, #28ace2,
  lighten($dark-layout, 7%);
// Menu title color
$color-title-name: blue, red, purple, lightblue, dark;
$color-title-color: $primary-color, #ff5252, #9575cd, #28ace2,
  lighten($dark-layout, 7%);

// layout-6 background color
$layout-6-bg-color: #28ace2;

//$theme-font-family: 'Open Sans', sans-serif;
$theme-font-family: "maerskText";
$theme-font-size: 14px;
$theme-background: rgba(237, 237, 237, 0.3);
$theme-font-color: #888;
$theme-heading-color: #111;

$color-name: c-blue, c-red, c-green, c-yellow, c-purple;
$color-color: $primary-color, $danger-color, $success-color, $warning-color,
  $purple-color;
$color-bt-name: primary, danger, success, warning, info;
$color-bt-color: $primary-color, $danger-color, $success-color, $warning-color,
  $info-color;

// form-control variables
$form-bg: #f0f3f6;

$theme-colors: () !default;
$theme-colors: map-merge(
  (
    "primary": $primary-color,
    "secondary": $secondary-color,
    "success": $success-color,
    "info": $info-color,
    "warning": $warning-color,
    "danger": $danger-color,
    "light": $light-color,
    "dark": $dark-color,
  ),
  $theme-colors
); // Color contrast
$yiq-contrasted-threshold: 200 !default;
$yiq-dark-color: #37474f;
$yiq-light-color: #fff;
$theme-color-interval: 8% !default;
$alert-bg-level: -10 !default;
$alert-border-level: -9 !default;
$alert-color-level: 6 !default;

//************************************************NAVBAR************************************************//
//COLOR CODES
$darkblue__color: #00243d;
$info__color: #42b0d5; // also a brand color
$light__border__color: #ededed;
$gray__color: gray;
$lightest__gray__color: #e0e0e0;
$light__gray__color: #f5f5f5;
$black__color: #141414;
$black__shade__color: #263238;
$red__color: #ff725e;
$blue__shade__color: #28ace2;
$white__color: #fff;
$white__gray__color: #ebebeb;
$blue__light__color: #b5e0f5;
$success__color: #40ab35;
$dark__red__color: #6b0d05;
$darkest__blue__color: #005487;
$text__color__red: #fa6a55;
$blue__color: #058ec6;
$blue__shades__color: #77c6e0;
$light__white: #ececec;
$border__gray__color: #cfcfcf;
$font__black__color:#353535;

//FONT FAMILY
$text__regular: MaerskText-Regular;
$text__light: MaerskText-Light;
$text__headline__regular: MaerskHeadLine-Regular;
$text__headline__light: MaerskHeadline-Light;
$text__headline__bold: MaerskHeadline-Bold;
$text__bold: MaerskText-Bold;
