/* Add this CSS to your MaerskModalsTable.css file */
.mrt-row-actions .edit-icon {
  opacity: 0; /* Initially hide the icon */
  transition: opacity 0.3s ease; /* Apply smooth transition effect */
}

.mrt-row-actions.hovered .edit-icon {
  opacity: 1; /* Show the icon on hover */
}

.delete-icon:hover {
  color: rgb(132, 196, 217);
}

.edit-icon:hover {
  color: rgb(132, 196, 217);
}
