.create-job-main{
  padding-top: 30px;
  padding-inline: 3.5%;
  display: grid;
  grid-template-columns: 2fr 7.2fr 1.8fr;
  /* border:1px solid red; */
}

/* .foldertree-inner-contanier{
  height: 76vh;
  margin-top: 5px;
  overflow: scroll;
  background-color: #fff;
  border-radius: 4px;
  border: 1.2px solid #eaeaea;
} */

/* .foldertree-outer-contanier{
  height: 80vh;
  overflow: hidden;
} */

.custom-link {
}
