input {
  padding: 1px 5px;
  width: 350px;
}

.button-box {
  /* width: 300px; */
  display: flex;
  justify-content: flex-end;
  /* margin: 15px; */
  text-align: center;
  /* margin-left: 140px; */
}

.edit-button {
  width: 70px;
  padding: 2px;
  margin-right: 15px;
  color: rgb(255, 255, 255);
  background-color: black;
  border: 1px solid rgb(0, 0, 0);
  border-radius: 3px;
  cursor: pointer;
}

.edit-button-Disabled {
  width: 70px;
  padding: 2px;
  margin-right: 15px;
  color: rgb(255, 255, 255);
  background-color: rgb(142, 141, 141);
  border: 1px solid rgb(142, 141, 141);
  border-radius: 3px;
  cursor: pointer;
}
