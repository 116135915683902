.arrayItem ul {
  display: flex;
  flex-wrap: wrap;
  width: 500px;
}

.enteredText ul{
  padding-left: 2px ;
  padding-bottom: 0;
  margin-bottom: 0;
}

.cancel {
    cursor: pointer;
    margin-left: 5px;
    border-radius: 50px; 
    color: red;
    padding-bottom: 5px;
    position: relative;
    bottom: 9px; 
  }

.enteredText li {
  border: 1px solid grey;
  padding: 4px 8px;
  border-radius: 9px;
  background: #ffffff;
  /* background: rgb(221, 220, 220); */
}

.enteredText {
  height: 50px;
  border: 1px solid grey;
  border-radius: 5px;
  margin-left: 5px;
}

.round-blinker {
  position: relative;  
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: grey; /* Adjust based on status */
  display: inline-block;
  margin-right: 25px;
  cursor: pointer; /* Indicate it's hoverable */
  top: 7px;
}


.tooltip-text {
  visibility: hidden; /* Start hidden */
  width: 160px;  
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 150%; /* Adjust as needed */
  left: 50%;
  transform: translateX(-50%); /* Center horizontally */
  opacity: 0; /* Start fully transparent */
  transition: visibility 0s, opacity 0.5s ease; /* Smooth transition for opacity */
}
 

.round-blinker:hover .tooltip-text {
  visibility: visible;
  opacity: 1; /* Fully visible on hover */
}

.tooltip-text {
  transform: translateX(-50%); /* Center the tooltip */
  opacity: 0;
  transition: opacity 0.3s ease;
}


.blinker-base {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 25px;
  position: relative;
  top: 8px;
}

.not-started {
  background-color: grey;
}

.running {
  background-color: #39FF14; /* Neon Green */
  animation: blinker 1.5s infinite;
}

.completed {
  background-color: #006400; /* Bottle Green */
}

@keyframes blinker {
  50% {
    opacity: 0.5;
  }
}

/* .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper{
  max-height: 64% !important;
} */ 
