.inputFieldWrapper {
  margin: 20px;
}


.inputFieldWrapper input {
  padding: 6px;
  background-color: rgba(247, 247, 247, 0.6);
  outline: none;
  border-radius: 5px;
  border: 1.2px solid #eaeaea;
  width: 100%;
}

.inputFieldWrapper select {
  padding: 6px;
  background-color: rgba(247, 247, 247, 0.6);
  outline: none;
  border-radius: 5px;
  border: 1.2px solid #eaeaea;
  width: 100%;
}

.connectionName {
  /* border: 1px solid red; */
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
}

.connectionNameInputField {
  width: 300px;
}

.connectionName select {
  min-width: 200px;
  padding: 6px;
  border-radius: 5px;
  border: 1.2px solid #eaeaea;
  background-color: rgba(247, 247, 247, 0.6);
}

.connectionInput {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 22px;
  flex-wrap: wrap;
}

.connectionInput input {
  padding: 4px;
  outline: none;
  border-radius: 5px;
  border: 1.2px solid #eaeaea;
  background-color: rgba(247, 247, 247, 0.6);
  max-width: 250px;
}

.logo {
  width: 50px;
  margin: 10px;
  text-align: right;
  /* border: 1px solid red; */
}

.connectionLogo {
  width: 50px;
  margin-left: 10px;
  /* border: 1px solid red; */
}

.techDetails {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.techDetails span {
  font-size: 12px;
  margin-right: 20px;
  /* border: 1px solid red; */
  padding: 5px;
  border-radius: 5px;
  background-color: rgb(54, 119, 241);
  color: white;
}

.button {
  margin-left: 20px;
  text-align: center;
}

.password-input-container {
  display: flex;
  text-align: center;
  justify-content: center;
}

.password-input-container p {
  border: 1px solid grey;
  border-radius: 5px;
  text-align: middle;
  font-size: 10px;
  padding-top: 11px;
  padding: 5px;
  margin-left: 5px;
  cursor: pointer;
}

.sourceDetails {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid lightblue;
  border-radius: 5px;
  padding: 10px;
  height: 50px;
  width: max-content;
}
