.nav__cont {
  position: fixed;
  width: 60px;
  top: 110;
  height: calc(100vh - 50px);
  z-index: 100;
  /* background-image: radial-gradient( circle 815px at 23.4% -21.8%,  rgba(9,29,85,1) 0.2%, rgba(0,0,0,1) 100.2% ); */

  background-color: white;
  overflow: hidden;
  transition: width 0.3s ease;
  cursor: pointer;
  box-shadow: 4px 7px 10px rgba(0, 0, 0, 0.4);
  &:hover {
    width: 260px;
    /* background:#40b0d5; */
    /* background: rgb(255, 255, 255);;
    background: linear-gradient(
      360deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(64, 176, 213, 1) 62%,
      rgba(0, 212, 255, 1) 100%
    ); */
  }
  @media screen and (min-width: 600px) {
    width: 80px;
  }
}

.backGblack{
  content: "";
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.6);
  /* filter: blur(6px); */
  left: 0px;
  top: 0;
  z-index:80;
  transition: all 0.3s ease;
}

.nav {
  list-style-type: none;
  color: white;
  &:first-child {
    padding-top: 11px;
  }
}

.nav__items {
  /* border: 1px solid grey; */
  /* padding-bottom: 1px; */
  font-family: "roboto";
  height: 60px;
  width: 240px;
  margin: 2px 7px;
  /* padding: 0px; */
  .Linktag {
    position: relative;
    display: block;
    /* top: -35px; */
    padding-left: 5px;
    padding-right: 5px;
    transition: all 0.3s ease;
    margin-left: 30px;
    margin-right: 30px;
    text-decoration: none;
    color: black;
    font-family: "roboto";
    font-weight: 100 !important;
    font-size: 1.35em !important;
    /* border: 1px solid red; */
    &:after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      /* border-radius: 2px; */
      /* background: radial-gradient(
        circle at 94.02% 88.03%,
        #40b0d5,
        transparent 100%
      ); */
      opacity: 0;
      transition: all 0.5s ease;
      z-index: -10;
    }
  }
  &:hover .Linktag:after {
    opacity: 1;
  }
  /* svg {
    width: 16px;
    height: 16px;
    position: relative;
    left: -25px;
    cursor: pointer;
    @media screen and(min-width:600px) {
      width: 32px;
      height: 32px;
      left: -15px;
    }
  } */
}

nav li p {
  font-weight: 500 !important;
  font-size: 19px !important;
  cursor: pointer;
}

/* body {
  height: 100vh;
  background: radial-gradient(circle at 94.02% 88.03%, #54a4ff, transparent 100%),radial-gradient(circle at 25.99% 27.79%, #ff94fb, transparent 100%),radial-gradient(circle at 50% 50%, #000000, #000000 100%);
} */

/* h1 {
  margin-top: 6rem;
  margin-left: 80px;
  text-align: center;
  font-family: "Roboto";
  font-weight: 100;
  font-size: 4rem;
  text-transform: uppercase;
  color: black;
  letter-spacing: 6px;
  text-shadow: 10px 10px 10px rgba(0, 0, 0, 0.3);
} */

.nav__items img {
  width: 40px;
  background: white;
  border-radius: 4px;
  border: 1px solid grey;
  padding: 4px; 
  margin-left: 10px;
}

.nav li {
  border: 0.001px solid white !important;
  background: transparent; /* Set an initial background */
  transition: all 0.5s ease; /* Apply transition to all properties */
  width: 320px;
  &:hover {
    border: 0.001px solid grey !important;
    /* background-color: #f0f0f0; /* Example: change  */

    color: black;
    transition: all 0.5s ease;
    /* background:#8ed5ec; */
    background: radial-gradient(
      circle at 94.02% 88.03%,
      #27AAD0,
      transparent 100%
    );
  }
}

.nav__items {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}

.nav__items.navItemsStyling {
  position: absolute; 
  bottom: 0px;  
  left: 0; 
  margin: 0px;
  width: 100%;
  padding-left: 7px;
  background-color: #002133;
  cursor:none;
}

 

li{
  border-radius: 0px;
}