.value-JobView {
  border: 0.4px solid gray;
  padding: 5px;
  border-radius: 4px;
  width: 350px;
  min-height: 25px;
  overflow: auto;
  font-size: 13.5px;
}

.task-details {
  margin-inline: 12px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 95%;
  overflow: auto;
  padding-bottom: 4px;
}

.jobview-paper {
  padding: 12px;
  overflow-x: hidden;
}

.query-box {
  border: 0.4px solid gray;
  /* padding: 8px; */
  /* background: #000; */
  color: white;
  border-radius: 4px;
  max-height: 115px;
  min-width: 450px;
  overflow: auto;
  font-size: 13.5px;
}

.button-tab-jobview{
  display: flex;
  align-items: center;
  column-gap:1rem;
  justify-content: flex-start;
}