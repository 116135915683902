.mainWrapper {
  padding-top: 30px;
  padding-left: 15px;
  /* border: 1px solid red; */
}

.shortcuts { 
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 5px;
  flex-wrap: wrap;
  background-color: #E4F4FB;
  padding: 20px 10px;
  margin: 20px 0px;
}


.shortcuts .infoCard { 
  width: 230px;
  margin-right: 40px;
  height: 200px;
  border-radius: 10px;
  padding: 10px;
  border: 1.2px solid #eaeaea;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  text-align: center;
  margin-top: 45px;
  background-color: white; 
  
}

.shortcuts .infoCard:hover { 
  cursor: pointer;
}

Link:hover{
 color: black;
}

.datacraftInfo{
  margin: 40px 10px;

  margin-left: 9%;
  font-size: 22px;
  margin-bottom: 0px;
  width: 80%;
  /* border: 1px solid red; */
}

.shortcuts span{
  font-size: 17px;
  font-weight: 800;
}

.shortcuts p{
    margin-top:20px ;
    font-size: 12px;
}

.shortcuts .infoCard:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  transform: scale(1.025);
}

.shortcuts img {
  height: 40px;
  width: 50px;
  margin-right: 10px;
}

.versionDetails {
  /* height: 70px; */ 
  min-height: 70px;
  background: #27aad0;
  margin: 0px 80px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.7em;
  color: white;
}

.videoBlock h5{
    margin: 20px;
}

.arrow-box {
    position: relative;
    background-color: #00AEEF; /* Adjust to the lighter blue background color of your choice */
    padding: 20px; /* Adjust the padding to fit your content */
    color: white; /* Text color */
    /* Other styling for your box */
  }
  
  .arrow-box:before {
    content: "";
    position: absolute;
    top: 0;
    left: 10px;
    width: 100px; /* Width of the arrow */
    height: 100%; /* Height should match the arrow box for a uniform look */
    background-color: #015A84; /* The darker blue color for the arrow */
    clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%);
  }
  
.userGuide{
  font-size: 25px;
  position: relative;
  bottom: 300px;
  left: 120px;
}

/* fliping card */

.singleCardWrapper{
  width: 200px;
  height: 180px; 
  perspective: 1000px;
  border-radius: 20px;
  /* border: 0.1px solid red; */
  position: relative;
  top: 10px;

  /* box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); */
}

.singleCardWrapper .imageWrapper{
  width: 200px;
  height: 180px;
  padding-top: 20px;
  text-align: center;
  transform: rotateY(0deg);
  border-radius: 20px;
  /* box-shadow: 0px 0px 10px white; */
  transition: all 0.5s ease-in-out;
  background-color: white;
  border: 0.1px solid rgb(215, 215, 215);
  /* color: white; */
}

.imageWrapper .image{
  height: 80px;
  width: 80px;
}

.imageWrapper h3{
  margin-top: 20px;
}

.singleCardWrapper:hover .imageWrapper{
  transform: rotateY(-90deg);
}

.singleCardWrapper .infoContent{
  /* box-shadow: 0px 0px 10px white; */
  
  width: 200px;
  height: 180px;
  position: absolute;
  top: 0;
  left: 0;
  padding: 2% 2%;
  text-align: center;
  background-color: #015A84;
  color: white;
  transform: rotateY(-90deg);
  transition: all 0.5s ease-in-out;
  border-radius: 20px;
  overflow-x: hidden;

}

.singleCardWrapper:hover .infoContent{
 transform: rotateY(0deg);
}

.landingPageLogo{
  width: 10px;
  height: 10px;
  /* border: 1px solid red;  */
}
.imageWrapperf{
  width: 55px;
}
.imageWrapperf img{
  width: 30px;
  height: 30px;
  /* margin-top: 10px; */
  margin: 10px auto;
  /* border: 1px solid red; */
}


@media screen and (max-width:1295px) {
  .arrow-box:before { 
    clip-path: none;
    width: 0px;
  }
}