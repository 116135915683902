.expandable-div {
  overflow: hidden;

  margin-top: 14px;
  border-radius: 4px;
}

.content.expanded {
  height: 300px; /* Adjust this value to the desired expanded height */
  /* background-color: lightgray; */
  background-color: #eaf6f6;
  border: 1px solid lightgray;
}

.content {
  height: 35px;
  overflow: hidden;
  transition: max-height 0.3s ease;
  padding: 6px;
  /* background-color: lightgray; */
  background-color: #eaf6f6;
  border: 1px solid lightgray;
  display: flex;
  flex-direction: column;
}
