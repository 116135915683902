.job-btn {
  background-color: $info__color;
  font-family: $text__regular;
  color: $white__color;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  font-size: 14px;

  &:hover {
    background-color: darken($info__color, 8%);
    color: $white__color;
  }

  &:active {
    background-color: darken($info__color, 8%) !important;
    color: $white__color !important;
    outline: none;
  }
}

.nar-wid{
  width: auto !important;
  height: 1.8rem !important;
}

.mar-bottom{
  margin-bottom: 1rem;
}