.data-card{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: auto;
  width: 12rem;
  border: none;
  border-radius: 8px;
  padding: 14px;
  margin-bottom: 30px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  /* cursor: pointer; */
}

.data-card:hover{
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  transform: scale(1.025);
}

.data-card-content{
  text-align: center;
}