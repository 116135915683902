.mainWrapper {
  /* border: 1px solid red; */
  padding-top: 70px;

}

.inputBox {
  text-align: center;
  /* border: 1px solid red; */
  margin: 25px auto;
  width: max-content;
}

.inputField {
  border-radius: 5px;
  outline: none;
  border: 1px solid grey;
  padding: 7px;
  width: 450px;
}

.cardWrapper {
  /* border: 1px solid green; */
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.singleCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 0.5px solid gray;
  border-radius: 5px;
  padding: 10px 20px;
  margin: 20px;
  min-width: 280px;
  cursor: pointer;

  /* background: linear-gradient(145deg, #e6e6e6, #ffffff); */
  background: linear-gradient(145deg, #ffffff, #ffffff);
  /* box-shadow:  3px 3px 6px #d6d6d6ef,
  -3px -3px 6px #ffffff; */
  background-color: #fff;
  border-radius: 4px;
  border: 1.2px solid #eaeaea;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
}

.singleCard:hover{
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  transform: scale(1.025);
}

.imageText {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  margin-right: 40px;
  max-width: 150px;
}

.techLogo {
  margin-right: 10px;
  width: 40px;
}

.techName {
  font-size: 15px;
}

.otherInfo {
  font-size: 10px;
  /* border: 1px solid grey; */
  padding: 2px 3px;
  border-radius: 5px;
  background-color: rgb(54, 119, 241);
  color: white;
}


.logoSize{
  width:"40px"
}